// 登录接口
import {
	axios
} from '../../utils/http.js'

const NoticeApi = {
	NoticeList: '/v3/Notice/List',//列表
	NoticeAdd: '/v3/Notice/Add',//添加
	NoticeDel: '/v3/Notice/Del',//删除
	
	DevBindList: '/v3/Device/DeviceNoticeBind_List',//报警策略绑定-列表
	DevSenList: '/v3/DeviceSensor/List',//设备列表
	
	NoticeBindAdd: '/v3/Notice/Bind_Add',//绑定设备
	NoticeBindDel: '/v3/Notice/Bind_Del',//绑定设备
	
	NoticeGet: '/v3/Notice/Get',//通知机制
	NoticeEdit: '/v3/Notice/Edit',//提交
	NoticeReset: '/v3/Notice/Reset_Mechanism',//重置
	NoticeUlist: '/v3/Notice/User_List',//重置
	
	
}
export function NoticeUlist(data) {
	return axios({
		url: NoticeApi.NoticeUlist,
		method: 'post',
		data:data 
	})
}
export function NoticeReset(data) {
	return axios({
		url: NoticeApi.NoticeReset,
		method: 'post',
		data:data 
	})
}
export function NoticeEdit(data) {
	return axios({
		url: NoticeApi.NoticeEdit,
		method: 'post',
		data:data 
	})
}
export function NoticeGet(data) {
	return axios({
		url: NoticeApi.NoticeGet,
		method: 'post',
		data:data 
	})
}
export function NoticeBindDel(data) {
	return axios({
		url: NoticeApi.NoticeBindDel,
		method: 'post',
		data:data 
	})
}
//设备列表
export function NoticeBindAdd(data) {
	return axios({
		url: NoticeApi.NoticeBindAdd,
		method: 'post',
		data:data 
	})
}
//设备列表
export function DevSenList(data) {
	return axios({
		url: NoticeApi.DevSenList,
		method: 'post',
		data:data 
	})
}
//报警策略绑定-列表
export function DevBindList(data) {
	return axios({
		url: NoticeApi.DevBindList,
		method: 'post',
		data:data 
	})
}
//删除
export function NoticeDel(data) {
	return axios({
		url: NoticeApi.NoticeDel,
		method: 'post',
		data:data 
	})
}
//添加
export function NoticeAdd(data) {
	return axios({
		url: NoticeApi.NoticeAdd,
		method: 'post',
		data:data 
	})
}
//列表
export function NoticeList(data) {
	return axios({
		url: NoticeApi.NoticeList,
		method: 'post',
		data:data 
	})
}
