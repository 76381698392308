<template>
	<div class="WarningStrategy">
		<div class="WarningStrategy-T">
			<div class="WarningStrategyL">
				<Breadcrumb></Breadcrumb>
				<div class="RightsManagement-searchs">
					<div class="RightsManagement-searchs1">
						<inputs @inputs="inputValue" :inputShow="true" inputPlace="请输入名称"></inputs>
					</div>
					<div class="WarningStrategyit-mai1-btn">
						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div>
			</div>
			<div class="WarningStrategyR">
				<div class="RightsManagement-searchsMai">
					<el-button v-permission="'DeviceNotice:Add'" @click="clickNew()" type="primary"
						icon="el-icon-plus">新增策略</el-button>
					<!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
				</div>
			</div>
		</div>
		<div class="WarningStrategy-B">
			<!-- <el-button @click="clickNew()" icon="el-icon-plus" style="margin-bottom: 20px;">新增策略</el-button> -->
			<el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" border>
				<template slot="empty">
					<noData></noData>
				</template>
				<el-table-column prop="T_name" label="策略名称">
				</el-table-column>
				<el-table-column prop="address" label="操作" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						<el-button v-permission="'DeviceNotice:Bind'" size="small"
							@click="SetBinding(scope.row)">绑定</el-button>
						<el-button v-permission="'DeviceNotice:Edit'" size="small"
							@click="SetEdit(scope.row)">编辑</el-button>
						<el-button v-permission="'DeviceNotice:Del'" size="small" @click="SetDel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-drawer title="绑定" :visible.sync="drawer" direction="rtl" @close="closes" :append-to-body="true" size="50%">
			<div style="padding:0 20px;height: 100%;">
				<el-tabs v-model="activeName" @tab-click="handleClick" style="height: 100%;display: flex;flex-direction: column;">
					<el-tab-pane label="已绑定传感器" name="first" style="height: 100%;">
                        <div class="y-table" infinite-scroll-delay="300" infinite-scroll-immediate="false" v-infinite-scroll="scroll1">
                            <div style="display: flex;align-items: center;">
                                <div class="y-table-item" style="width: 50px;">序号</div>
                                <div class="y-table-item" style="flex: 1;">设备名称</div>
                                <div class="y-table-item" style="flex: 1;">
                                    <el-button type="danger" size="small" @click="OneKeyDel">一键删除</el-button>
                                </div>
                            </div>
                            <div v-for="item,index in tableData1" :key="index" style="display: flex;align-items: center">
                                <div class="y-table-item" style="width: 50px;;">{{index+1}}</div>
                                <div class="y-table-item" style="flex: 1;">{{item.T_name}}</div>
                                <div class="y-table-item" style="flex: 1;">
                                    <el-button type="primary" icon="el-icon-delete" size="small" @click="SingleDel(item)">删除</el-button>
                                </div>
                            </div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="设备列表" name="second" style="height: 100%;overflow: hidden;box-sizing: border-box;">
                        <div style="display: flex;flex-direction: column;height: 100%;">
                            <el-form :inline="true" style="height: 70px;" class="demo-form-inline">
                                <el-form-item label="名称">
                                    <el-input v-model="pages2.T_name" placeholder="设备名称" style="width:150px;" @input="search"
                                        clearable></el-input>
                                </el-form-item>
                                <el-form-item label="分类">
                                    <el-select v-model="pages2.T_calssid" placeholder="请选择分类" style="width:150px;"
                                        @input="search">
                                        <el-option label="全部" value="">
                                        </el-option>
                                        <el-option v-for="item in selectVal" :key="item.Id" :label="item.T_name"
                                            :value="item.Id">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-form>
                            <div class="y-table" style="flex: 1;" infinite-scroll-delay="300" infinite-scroll-immediate="false" v-infinite-scroll="scroll2">
                                <div style="display: flex;align-items: center;">
                                    <div class="y-table-item" style="width: 50px;">序号</div>
                                    <div class="y-table-item" style="flex: 1;">设备名称</div>
                                    <div class="y-table-item" style="flex: 1;">
                                        <el-button type="danger" size="small" @click="OneKeyAdd">一键绑定</el-button>
                                    </div>
                                </div>
                                <div v-for="item,index in tableData2" :key="index" style="display: flex;align-items: center">
                                    <div class="y-table-item" style="width: 50px;;">{{index+1}}</div>
                                    <div class="y-table-item" style="flex: 1;">{{item.T_name}}</div>
                                    <div class="y-table-item" style="flex: 1;">
                                        <el-button type="primary" icon="el-icon-paperclip" size="small"
                                                @click="SingleAdd(item)">绑定</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-drawer>

		<el-dialog title="编辑" :visible.sync="dialogVisible" :append-to-body="true" width="1171px" style="min-height: 500px;"
			:before-close="closeEd" :close-on-click-modal="false" :close-on-press-escape="false">
			<div class="NewUser" style="min-height: 500px;display: flex;flex-direction: column;">
				<el-form label-width="70px">
					<el-form-item label="策略名称">
						<el-input v-model="ruleForm.T_name" clearable style="width: 50%;"></el-input>
					</el-form-item>
				</el-form>
				<div style="flex: 1;">
					<el-tabs v-model="activeName">
						<el-tab-pane label="通知机制" name="first">
							<div class="ovhide">
								<el-table :data="ruleForm.T_Notice_mechanism" :key="timeStamp" v-loading="tableLoad" border
									size="mini" height="400px" style="width: 100%;">
									<template slot="empty">
										<noData></noData>
									</template>
									<el-table-column label="报警类型" width="120">
										<template slot-scope="scope">
											<div>
												{{ scope.row[0] }}
											</div>
										</template>
									</el-table-column>

									<el-table-column width="50">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="选择第一列数据,双击全选当前列">
												处理
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<el-checkbox v-model="scope.row[1]" true-label="1" false-label="0">
												</el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column width="50">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="选择第一列数据,双击全选当前列">
												微信
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<el-checkbox v-model="scope.row[2]" true-label="1" false-label="0">
												</el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column width="60">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												持续秒
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[3]">
											</div>
										</template>
									</el-table-column>
									<el-table-column width="60">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												间隔秒
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[4]">
											</div>
										</template>
									</el-table-column>
									<el-table-column width="70">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												发送条数
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[5]">
											</div>
										</template>
									</el-table-column>


									<el-table-column label="" width="50">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="选择第一列数据,双击全选当前列">
												短信
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<el-checkbox v-model="scope.row[6]" true-label="1" false-label="0">
												</el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="" width="60">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												持续秒
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[7]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="" width="60">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												间隔秒
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[8]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="" width="70">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												发送条数
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[9]">
											</div>
										</template>
									</el-table-column>

									<el-table-column label="" width="50">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="选择第一列数据,双击全选当前列">
												电话
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<el-checkbox v-model="scope.row[10]" true-label="1" false-label="0">
												</el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="" width="60">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												持续秒
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[11]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="" width="60">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												间隔秒
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[12]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="" width="70">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												发送条数
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[13]">
											</div>
										</template>
									</el-table-column>



									<el-table-column label="" width="50">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="选择第一列数据,双击全选当前列">
												APP
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<el-checkbox v-model="scope.row[14]" true-label="1" false-label="0">
												</el-checkbox>
											</div>
										</template>
									</el-table-column>
									<el-table-column label="" width="60">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												持续秒
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[15]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="" width="60">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												间隔秒
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[16]">
											</div>
										</template>
									</el-table-column>
									<el-table-column label="" width="70">
										<template slot="header" slot-scope="scope">
											<div @dblclick="fastSet(scope.$index)" style="cursor: pointer;"
												title="在第一列输入数据,双击全选可全填当前列">
												发送条数
											</div>
										</template>
										<template slot-scope="scope">
											<div style="display: flex;justify-content: center;">
												<input type="text" class="inputValue" v-model="scope.row[17]">
											</div>
										</template>
									</el-table-column>
								</el-table>
							</div>
						</el-tab-pane>
						<el-tab-pane label="通知方式" name="second">
							<div style="height: 400px;">
								<el-tabs tab-position="left" style="height: 400px;">
									<el-tab-pane label="微信通知">
										<el-button style="margin: 10px 0;" @click="addnotData('wx')">添加微信通知</el-button>
										<el-table :data="ruleForm.T_Notice_wx" style="width: 100%" max-height="350px">
											<el-table-column label="用户名称" :show-overflow-tooltip="true">
												<template slot-scope="scope">
													{{ scope.row[1] }}
												</template>
											</el-table-column>
											<el-table-column label="操作">
												<template slot-scope="scope">
													<el-button type="primary" icon="el-icon-paperclip" size="small"
														@click="delnotData(scope.row, 'wx')">删除
													</el-button>
												</template>
											</el-table-column>
										</el-table>
									</el-tab-pane>
									<el-tab-pane label="短信通知">
										<el-button style="margin: 10px 0;" @click="addnotData('mes')">添加短信通知</el-button>
										<el-table :data="ruleForm.T_Notice_message" style="width: 100%" max-height="350px">
											<el-table-column label="用户名称" :show-overflow-tooltip="true">
												<template slot-scope="scope">
													{{ scope.row[1] }}
												</template>
											</el-table-column>
											<el-table-column label="操作">
												<template slot-scope="scope">
													<el-button type="primary" icon="el-icon-paperclip" size="small"
														@click="delnotData(scope.row, 'mes')">删除
													</el-button>
												</template>
											</el-table-column>
										</el-table>
									</el-tab-pane>
									<el-tab-pane label="电话通知">
										<el-button style="margin: 10px 0;" @click="addnotData('pho')">添加电话通知</el-button>
										<el-table :data="ruleForm.T_Notice_phone" style="width: 100%" max-height="350px">
											<el-table-column label="用户名称" :show-overflow-tooltip="true">
												<template slot-scope="scope">
													{{ scope.row[1] }}
												</template>
											</el-table-column>
											<el-table-column label="操作">
												<template slot-scope="scope">
													<el-button type="primary" icon="el-icon-paperclip" size="small"
														@click="delnotData(scope.row, 'pho')">删除
													</el-button>
												</template>
											</el-table-column>
										</el-table>
									</el-tab-pane>
									<el-tab-pane label="APP通知">
										<el-button style="margin: 10px 0;" @click="addnotData('app')">添加APP通知</el-button>
										<el-table :data="ruleForm.T_Notice_app" style="width: 100%" max-height="350px">
											<el-table-column label="用户名称" :show-overflow-tooltip="true">
												<template slot-scope="scope">
													{{ scope.row[1] }}
												</template>
											</el-table-column>
											<el-table-column label="操作">
												<template slot-scope="scope">
													<el-button type="primary" icon="el-icon-paperclip" size="small"
														@click="delnotData(scope.row, 'app')">删除
													</el-button>
												</template>
											</el-table-column>
										</el-table>
									</el-tab-pane>
								</el-tabs>
							</div>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div style="margin-top: 20px;display: flex;justify-content: flex-end;">
					<el-button type="danger" @click="dataReset" v-if="activeName == 'first'">重置数据</el-button>
					<el-button type="info" @click="showDis = true" v-if="activeName == 'first'">策略说明</el-button>
					<el-button type="primary" style="margin-left: 10px;" @click="subBtn">完成编辑</el-button>
				</div>
			</div>
			<el-dialog width="700px" title="策略说明" :visible.sync="showDis" append-to-body>
				<el-image style="width: 100%; height: 100%" :src="url" title="点击查看大图" :preview-src-list="srcList">
				</el-image>
			</el-dialog>
			<!-- 内层 -->
			<el-dialog title="添加通知方式" :visible.sync="innerVisible" append-to-body>
				<el-form :inline="true">
					<el-form-item>
						<el-input v-model="noticeName" placeholder="请输入名称搜索" clearable></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="noticeFun">查询</el-button>
					</el-form-item>

				</el-form>
				<el-table :data="innerTable" style="" height="400px">
					<el-table-column prop="T_name" label="名称">
					</el-table-column>
					<el-table-column label="操作">
						<template slot-scope="scope">
							<el-button type="primary" icon="el-icon-paperclip" size="small" @click="addData(scope.row)">
								添加
							</el-button>
							<!-- <el-button type="primary" :disabled="
							msg=='wx'? scope.row.T_wx == ''?true:false:
							msg=='mes'? scope.row.T_phone != ''?true:false:
							msg=='pho'? scope.row.T_phone != ''?true:false:false"
							icon="el-icon-paperclip" size="small">添加
							</el-button> -->
						</template>
					</el-table-column>
				</el-table>
			</el-dialog>
		</el-dialog>
	</div>
</template>

<script>
import {
	NoticeList,
	NoticeAdd,
	NoticeDel,
	DevBindList,
	DevSenList,
	NoticeBindAdd,
	NoticeBindDel,
	NoticeGet,
	NoticeEdit,
	NoticeReset,
	NoticeUlist
} from "@/api/equipmentManagement/WarningStrategy"
import {
	ClassAll
} from "@/api/ClassAll.js"
export default {
	data() {
		return {
			url: require('@/assets/img/des.jpg'),
			srcList: [
				require('@/assets/img/des.jpg'),
				require('@/assets/img/des.jpg')
			],
			showDis: false,
			timeStamp: false,//控制tab双击的状态变化，作为key
			msg: '',
			ResetId: {
				T_id: null,
			},
			tableLoad: true,
			pages: {
				T_name: '',
				page: 1,
				page_z: 99999,
			},
			pages1: {
				T_notice_id: '',
				page: 1,
				page_z: 20,
			},
			pages2: {
				T_name: '',
				T_state: 1,
				T_calssid: '',
				page: 1,
				page_z: 20,
			},
			selectVal: [], //分类select列表
			Page_size1: 1,
			Page_size2: 1,


			inputValue2: '',
			formInline: {
				user: '',
				region: ''
			},
			EditData: {}, //编辑的提交数据包
			drawer: false,
			activeName: 'first',
			tableData: [],
			tableData1: [], //已绑定
			tableData2: [], //设备列表
			dialogVisible: false,
			innerVisible: false,
			innerTable: [],
			noticeName: '',
			ruleForm: {},
		}
	},
	watch: {
		innerVisible(value) {
			////console.log('变化',value)
			!value ? this.noticeName = '' : ''
		}
	},
	mounted() {
		this.GetNoticeDataApi()
	},
	methods: {
		noticeFun() {
			let str = this.noticeName

			if (str.indexOf('内管-') == 0) {
				str = str.substring(3, str.length)
			}

			this.GetUlistApi(str)
		},
		fastSet(row) {
			var i = this.ruleForm.T_Notice_mechanism[0][row]//第一列的状态\
			var arr = this.ruleForm.T_Notice_mechanism
			var arr1 = []
			arr.forEach(item => {
				item[row] = i
				arr1.push(item)
			})
			this.ruleForm.T_Notice_mechanism = arr1
			this.timeStamp = !this.timeStamp
		},
		addData(data) { //点击添加通知方式
			if (this.msg == 'wx') {
				if (data.T_wx == '') {
					this.$confirm('未绑定微信哦，请前往该用户个人中心绑定！', '提示', {
						confirmButtonText: '我知道了',
						cancelButtonText: '我知道了',
						type: 'warning',
						center: true,
						showConfirmButton: true,//显示确定按钮
						showCancelButton: false//显示取消按钮
					}).then(() => {
						// this.$router.push('/systemManagement/personal')
					}).catch(() => { });
				} else {
					var t = this.findArr(this.ruleForm.T_Notice_wx, data)
					if (!t) {
						this.ruleForm.T_Notice_wx.push([data.T_uuid, data.T_name])
						this.$message.success('加入成功')
					} else {
						this.$message.error('已经添加过，请勿重复添加')
					}
				}
			} else if (this.msg == 'mes') {
				if (data.T_phone == '') {
					this.$confirm('未绑定手机号码哦，请前往个人中心绑定, 是否前往绑定?', '提示', {
						confirmButtonText: '马上绑定',
						cancelButtonText: '取消绑定',
						type: 'warning',
						center: true
					}).then(() => {
						this.$router.push('/systemManagement/personal')
					}).catch(() => { });
				} else {
					var t = this.findArr(this.ruleForm.T_Notice_message, data)
					if (!t) {
						this.ruleForm.T_Notice_message.push([data.T_uuid, data.T_name])
						this.$message.success('加入成功')
					} else {
						this.$message.error('已经添加过，请勿重复添加')
					}
				}
			}else if(this.msg == 'app'){
				console.log('结果',data)
					var t = this.findArr(this.ruleForm.T_Notice_app, data)
					if (!t) {
						this.ruleForm.T_Notice_app.push([data.T_uuid, data.T_name])
						this.$message.success('加入成功')
					} else {
						this.$message.error('已经添加过，请勿重复添加')
					}
			} else {
				if (data.T_phone == '') {
					this.$confirm('未绑定手机号码哦，请前往个人中心绑定, 是否前往绑定?', '提示', {
						confirmButtonText: '马上绑定',
						cancelButtonText: '取消绑定',
						type: 'warning',
						center: true
					}).then(() => {
						this.$router.push('/systemManagement/personal')
					}).catch(() => { });
				} else {
					var t = this.findArr(this.ruleForm.T_Notice_phone, data)
					if (!t) {
						this.ruleForm.T_Notice_phone.push([data.T_uuid, data.T_name])
						this.$message.success('加入成功')
					} else {
						this.$message.error('已经添加过，请勿重复添加')
					}
				}
			}
		},
		findArr(arr, obj) {
			return arr.some((item) => item[0] == obj.T_uuid) //item[0]!=obj.T_uuid
		},
		addnotData(ms) {
			this.msg = ms
			this.innerVisible = true
			this.GetUlistApi('')
		},
		delnotData(data, ms) { //从数组删除
			var arr = ms == 'wx' ? this.ruleForm.T_Notice_wx : ms == 'mes' ? this.ruleForm.T_Notice_message : ms == 'app' ? this.ruleForm.T_Notice_app : this.ruleForm.T_Notice_phone
			const b = arr.findIndex(item => item[0] == data[0])
			arr.splice(b, 1)
		},
		GetUlistApi(e) {
			NoticeUlist({ T_name: e }).then(res => {
				if (res.data.Code === 200) {
					var arrAdmin = this.SetinntData(res.data.Data.AdminList, 'admin')
					var arrUser = this.SetinntData(res.data.Data.UserList, 'user')
					////console.log('处理完',arrAdmin,arrUser)
					this.innerTable = [...arrAdmin, ...arrUser]
					////console.log('二级table', this.innerTable)
				}
			})
		},
		SetinntData(arr, mbol) { //设置二级通知方式数据
			var dataArr = []
			if (arr != null) {
				arr.forEach(item => {
					dataArr.push({
						T_name: mbol == 'admin' ? '内管-' + item.T_name : '' + item.T_name,
						T_uuid: item.T_uuid,
						T_phone: item.T_phone,
						T_wx: item.T_wx,
					})
				})
				return dataArr
			} else {
				return []
			}
		},
		dataReset(id) { //重置数据
			this.$confirm('该操作为重置数据，请谨慎使用, 是否重置?', '提示', {
				confirmButtonText: '立即重置',
				cancelButtonText: '取消重置',
				type: 'warning',
				showClose: false
			}).then(() => {
				const loading = this.$loading({
					lock: true,
					text: '正在重置数据，请稍后...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				NoticeReset(this.ResetId).then(res => {
					if (res.data.Code === 200) {
						this.$message.success('重置数据成功')
						this.GetnotiocApi(this.ResetId.T_id)
					}
					loading.close()
				})
			}).catch(() => {
				this.$message.info('已取消重置数据');
			});
		},
		closeEd() { //点击关闭X
			this.$confirm('检测到数据未提交, 是否提交?', '提示', {
				confirmButtonText: '立即提交',
				cancelButtonText: '不用提交',
				type: 'warning',
				showClose: false,
				closeOnClickModal: false
			}).then(() => {
				this.subBtn()
			}).catch(() => {
				this.$message.info('已取消提交数据');
				this.dialogVisible = false
			});
		},
		subBtn() {
			var arr = this.ruleForm
			let a = this.setSubData(this.ruleForm.T_Notice_app, '/')
			var obj = {
				T_id: this.ruleForm.T_id,
				T_Notice_bind: this.ruleForm.T_Notice_bind,
				T_Notice_mailbox: this.setSubData(this.ruleForm.T_Notice_mailbox, '/'),
				T_Notice_mechanism: this.setSubData(this.ruleForm.T_Notice_mechanism, ','),
				T_Notice_message: this.setSubData(this.ruleForm.T_Notice_message, '/'),
				T_Notice_phone: this.setSubData(this.ruleForm.T_Notice_phone, '/'),
				T_Notice_wx: this.setSubData(this.ruleForm.T_Notice_wx, '/'),
				T_Notice_app: this.setSubData(this.ruleForm.T_Notice_app, '/'),
				T_name: this.ruleForm.T_name,
			}
			NoticeEdit(obj).then(res => {
				if (res.data.Code == 200) {
					this.GetNoticeDataApi()
					this.dialogVisible = false
					this.$message.success('策略数据提交数据成功');
				}
			})
		},
		setSubData(data, mbol) {
			if (mbol == ',') {
				var strings = ''
				data.forEach(item => {
					strings = strings + item.toString() + '|'
				})
				////console.log(strings)
				return strings
			} else {
				if (data.length != 0) { //有数据
					var strings = ''
					data.forEach(item => {
						strings = strings + item.join("/") + '|'
					})
					return strings
				} else {
					return '-'
				}
			}
		},
		SetEdit(e) { //编辑
			////console.log(e)
			if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
				this.$refs.ruleForm.resetFields();
			}
			this.ResetId.T_id = e.Id
			this.dialogVisible = true
			this.GetnotiocApi(e.Id)
		},
		GetnotiocApi(e) { //编辑获取数据
			NoticeGet({
				T_id: e
			}).then(res => {
				console.log('返回8',res)
				if (res.data.Code == 200) {
					this.ruleForm = {
						T_id: res.data.Data.Id,
						T_Notice_bind: res.data.Data.T_Notice_bind,
						T_Notice_mailbox: this.setMecData(res.data.Data.T_Notice_mailbox, '/'),
						T_Notice_mechanism: this.setMecData(res.data.Data.T_Notice_mechanism, ','),
						T_Notice_message: this.setMecData(res.data.Data.T_Notice_message, '/'),
						T_Notice_phone: this.setMecData(res.data.Data.T_Notice_phone, '/'),
						T_Notice_wx: this.setMecData(res.data.Data.T_Notice_wx, '/'),
						T_Notice_app: this.setMecData(res.data.Data.T_Notice_app, '/'),
						T_name: res.data.Data.T_name,
					}
				}
				console.log('wewe', this.ruleForm)
				this.tableLoad = false
			})
		},
		setMecData(e, mbol) { //处理编辑通知机制-数据
			var arrData = []
			if (e != '') {
				var arr = e.split('|')
				arr.pop()
				arr.forEach(item => {
					arrData.push(item.split(mbol))
				})
				return arrData
			} else {
				return arrData
			}
		},
		clickNew() { //新增
			this.$prompt('请策略名称', '提示', {
				confirmButtonText: '立即提交',
				cancelButtonText: '取消新增',
				inputPattern: /^.+$/,
				inputErrorMessage: '策略名称不能为空'
			}).then(({
				value
			}) => {
				const loading = this.$loading({
					lock: true,
					text: '正在进行，请稍后...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
				NoticeAdd({
					T_name: value
				}).then(res => {
					loading.close()
					if (res.data.Code === 200) {
						this.$message.success('新增策略成功')
						this.dialogVisible = false
						this.pages.page = 1
						this.GetNoticeDataApi()
					}
				})
			}).catch(() => { });
		},
		handleClick(e) { //切换tab
			if (e.name === "first") {
				this.tableData1 = []
				this.pages1.page = 1
				this.GetbingApi() //已经绑定列表Api
			} else {
				this.tableData2 = []
				this.pages2.page = 1
				this.GetsevSenApi() //已经绑定列表Api
			}
		},
		OneKeyAdd() { //一键绑定
			if (this.Page_size2 != this.pages2.page) {
				this.$confirm('数据还未全部滚动加载完, 当前操作只能绑定已滚动展示的数据', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '继续滚动',
					type: 'warning',
					center: true
				}).then(() => {
					this.forasyncFn()
				}).catch(() => {

				});
			}else{
				this.forasyncFn()
			}
		},

		async forasyncFn() {
			const loading = this.$loading({
				lock: true,
				text: '正在进行，请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			var j = 0
			for (let i = 0; i < this.tableData2.length; i++) {
				var res = await this.SetBandAddApi(this.tableData2[i])
				if (res.data.Code == 200) {
					j = j + 1
				}
			}
			loading.close()
			this.$message.success(`一键批量绑定完成，共${this.tableData2.length}个，成功${j}个`)
		},
		async SingleAdd(e) { //单项绑定
			var res = await this.SetBandAddApi(e)
			if (res.data.Code === 200) {
				this.$message.success('绑定成功')
			}
		},
		SetBandAddApi(obj) { //绑定api
			return new Promise(resolve => {
				NoticeBindAdd({
					T_notice_id: this.pages1.T_notice_id,
					T_sn: obj.T_sn,
					T_id: obj.T_id,
				}).then(res => {
					resolve(res)
				})
			})
		},
		async OneKeyDel() { //一键删除
			if (this.Page_size1 != this.pages1.page) {
				this.$confirm('数据还未全部滚动加载完, 当前操作只能解绑已展示数据', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '继续滚动',
					type: 'warning',
					center: true
				}).then(() => {
					this.forasyncDelFn()
				}).catch(() => {

				});
			}else{
				this.forasyncDelFn()
			}
			
		},
		async forasyncDelFn(){
			const loading = this.$loading({
				lock: true,
				text: '正在进行，请稍后...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			var j = 0
			for (let i = 0; i < this.tableData1.length; i++) {
				var res = await this.SetBandDelApi(this.tableData1[i])
				if (res.data.Code == 200) {
					j = j + 1
				}
			}
			loading.close()
			this.$message.success(`一键批量删除完成，共${this.tableData1.length}个，成功${j}个`)
			this.tableData1 = []
			this.pages1.page = 1
			this.GetbingApi() //已经绑定列表Api
		},
		async SingleDel(e) { //单项删除
			var res = await this.SetBandDelApi(e)
			if (res.data.Code === 200) {
				this.tableData1 = []
				this.pages1.page = 1
				this.GetbingApi() //已经绑定列表Api
				this.$message.success('删除成功')
			}
		},
		SetBandDelApi(obj) { //删除
			////console.log('删除', obj)
			return new Promise(resolve => {
				NoticeBindDel({
					T_notice_id: this.pages1.T_notice_id,
					T_sn: obj.T_sn,
					T_id: obj.T_id,
				}).then(res => {
					resolve(res)
				})
			})
		},
		search() { //绑定搜索
			////console.log('绑定搜索', this.pages2)
			this.tableData2 = []
			this.pages2.page = 1
			this.GetsevSenApi()
		},
		scroll1(){ 
                this.pages1.page = this.pages1.page + 1
                this.GetbingApi()
			},
			scroll2(){
                this.pages2.page = this.pages2.page + 1
                this.GetsevSenApi()
			},
		closes() {
			this.tableData1 = []
			this.tableData2 = []
			this.pages1.page = 1
			this.pages2.page = 1
			this.activeName = 'first'
		},

		SetBinding(e) { //打开绑定按钮
			this.drawer = true
			this.pages1.T_notice_id = e.Id
			this.GetbingApi() //已经绑定列表Api
			this.GetsevSenApi() //设备列表Api
			this.GetselectApi()
		},
		GetbingApi() { //已经绑定列表Api
			return new Promise(resolve => {
				DevBindList(this.pages1).then(res => {
					////console.log('返回---', res)
					if (res.data.Code == 200 && res.data.Data.DeviceSensor_lite != null) {
						this.Page_size1 = res.data.Data.Page_size
						this.tableData1 = [...this.tableData1, ...res.data.Data.DeviceSensor_lite]
						resolve(res)
					}
				})
			})
		},
		GetsevSenApi() { //设备列表Api
			return new Promise(resolve => {
				DevSenList(this.pages2).then(res => {
					if (res.data.Code == 200 && res.data.Data.Data != null) {
						this.Page_size2 = res.data.Data.Page_size
						this.tableData2 = [...this.tableData2, ...res.data.Data.Data]
						resolve(res)
					}
				})
			})
		},
		GetselectApi() { //分类select
			ClassAll({}).then(res => {
				if (res.data.Code == 200) {
					this.selectVal = res.data.Data.Data
				} else {
					this.selectVal = []
				}
			})
		},
		GetNoticeDataApi() { //列表
			NoticeList(this.pages).then(res => {
				////console.log('界面列表', res)
				if (res.data.Code == 200) {
					this.tableData = res.data.Data.Data
				}
			})
		},

		SetDel(val) { //删除
			this.$confirm('此操作将执行删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true
			}).then(() => {
				NoticeDel({
					T_id: val.Id
				}).then(res => {
					if (res.data.Code === 200) {
						this.$message.success('操作成功，已经删除')
						this.pages.page = 1
						this.GetNoticeDataApi()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		inputValue(e) {
			this.pages.T_name = e
			this.GetNoticeDataApi()
		},
	}
}
</script>

<style lang="scss">
.y-table{
    height: 100%;
    overflow:auto;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
}
.y-table-item{
    border-right: 1px solid #EBEEF5;
    border-bottom: 1px solid #EBEEF5;
    line-height: 40px;
    padding-left: 10px;
    font-size: 12px;
    color: #606266;
    font-weight: 500;
}

.el-tabs__content {
	flex: 1;
}

.inputValue {
	outline: none;
	text-align: center;
	border: 1px solid #DCDFE6;
	border-radius: 2px;
	height: 14px;
	box-sizing: border-box;
	width: 90%;
	font-size: 12px;
	padding: 8px 0;
	color: #909399;
}

.WarningStrategy {
	.WarningStrategy-T {
		display: flex;
		align-items: center;
		justify-content: space-between;
		background: rgba(#fff, 1);
		height: 70px;
		padding: 15px 10px;
		box-shadow: 5px 5px 10px -10px #000;

		.WarningStrategyL {
			display: flex;
			align-items: center;

			.RightsManagement-searchs {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 20px;

				.WarningStrategyit-mai1-btn {}

				.RightsManagement-searchs1 {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
		}

		.RightsManagement-searchsMai {
			display: flex;
			align-items: center;
			cursor: pointer;
		}
	}

	.WarningStrategy-B {
		background: rgba(#fff, 1);
		margin: 10px;
		padding: 20px 30px;
	}
}
</style>
